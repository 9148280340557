import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  Column,
  Columns,
  Heading,
  HiddenVisually,
  Link,
  Stack,
  Text,
} from 'braid-design-system';
import { useCallback, type ReactNode } from 'react';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import useSavedSearchesDashboard, {
  formatSummary,
} from '../../Dashboard/SignedInDashboard/SavedSearchesDashboard/useSavedSearchesDashboard';
import { CardsStack } from '../SharedComponent/CardsStack/CardsStack';
import { ErrorState } from '../SharedComponent/ErrorState/ErrorState';
import { MessageWrapper } from '../SharedComponent/MessageWrapper/MessageWrapper';
import { ViewAllLink } from '../SharedComponent/ViewAllLink/ViewAllLink';

import translations from './.vocab';

import * as styles from './SavedSearches.css';

const LOADING_SKELETON_COUNT = 3;

const SavedSearchSkeleton = () => (
  <Box boxShadow="borderNeutralLight" borderRadius="large" padding="medium">
    <Stack space="xxsmall">
      <SkeletonLine type="text" width={200} size="small" />
      <SkeletonLine type="text" width={260} size="small" />
    </Stack>
  </Box>
);

const SavedSearchesLoadingState = () => (
  <CardsStack>
    {new Array(LOADING_SKELETON_COUNT).fill(null).map((_, index) => (
      <SavedSearchSkeleton key={index} />
    ))}
  </CardsStack>
);

const SavedSearchesEmptyState = () => {
  const { t } = useTranslations(translations);
  return <MessageWrapper>{t('Use the Save search...')}</MessageWrapper>;
};

const SavedSearchesContent = ({
  displayState,
  savedSearches,
}: Pick<
  ReturnType<typeof useSavedSearchesDashboard>,
  'displayState' | 'savedSearches'
>) => {
  if (displayState === 'LOADING') {
    return <SavedSearchesLoadingState />;
  }

  if (displayState === 'ERROR') {
    return <ErrorState section="savedSearches" />;
  }

  if (savedSearches && savedSearches.length > 0) {
    return (
      <CardsStack>
        {savedSearches.map((savedSearch, index) => (
          <SavedSearch
            savedSearch={savedSearch}
            key={savedSearch.id}
            position={index + 1}
          />
        ))}
      </CardsStack>
    );
  }

  return <SavedSearchesEmptyState />;
};

const SavedSearches = () => {
  const { displayState, savedSearches, totalCount } =
    useSavedSearchesDashboard();
  const { t } = useTranslations(translations);
  const label = t('Saved searches');

  return (
    <Stack space="small">
      <Heading level="4">{label}</Heading>
      <Stack space="small">
        <SavedSearchesContent
          displayState={displayState}
          savedSearches={savedSearches}
        />
        <ViewAllLink
          loading={displayState === 'LOADING'}
          count={totalCount}
          section="savedSearches"
        />
      </Stack>
    </Stack>
  );
};

const SavedSearch = ({
  savedSearch,
  position,
}: {
  savedSearch: NonNullable<
    ReturnType<typeof useSavedSearchesDashboard>['savedSearches']
  >[number];
  position: number;
}) => {
  const { t } = useTranslations(translations);
  const { id, query, countLabel, name } = savedSearch;
  const summary = formatSummary(savedSearch.query.parameters);
  const saveSearchUrl = useLocalisedLinks({
    path: `/jobs?${query.searchQueryString}&savedsearchid=${id}`,
  });
  const analytics = useAnalyticsFacade();

  const translatedTitle = t('{countLabel} new job(s) for: {name}', {
    countLabel,
    count: Number(countLabel || 0),
    name,
    HiddenVisually: (children: ReactNode) => (
      <HiddenVisually key="screen-reader-only">{children}</HiddenVisually>
    ),
  });

  const onLinkClick = useCallback(() => {
    analytics.linkClicked({
      linkContext: {
        linkPosition: String(position),
        linkSection: 'Saved Searches',
      },
      linkName: 'dashboard-save-search-click',
      href: saveSearchUrl,
    });
  }, [analytics, position, saveSearchUrl]);

  return (
    <Link href={saveSearchUrl} className={styles.base} onClick={onLinkClick}>
      <Columns alignY="center" space="small">
        <Column>
          <HiddenVisually>
            <h4>{translatedTitle}</h4>
          </HiddenVisually>
          <Stack space="xxsmall">
            <Text size="small" weight="strong" maxLines={1}>
              {name}
            </Text>
            {summary ? (
              <Text size="xsmall" maxLines={1} tone="secondary">
                {summary}
              </Text>
            ) : null}
          </Stack>
        </Column>
        {countLabel !== '0' ? (
          <Column width="content">
            <Badge tone="positive">
              {t('{count} new', {
                count: countLabel,
              })}
            </Badge>
          </Column>
        ) : null}
      </Columns>
    </Link>
  );
};

export default SavedSearches;
