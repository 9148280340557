import { useTranslations } from '@vocab/react';
import { Box, Column, Columns, Stack, Text, Link } from 'braid-design-system';
import { useCallback } from 'react';

import { LazyLoadedImg } from 'src/components/HomePage/LazyLoadedImg/LazyLoadedImg';
import useSalaryDisplayLabel from 'src/hooks/useSalaryDisplayLabel/useSalaryDisplayLabel';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';
import { getSearchParams } from 'src/utils/urlParams';

import translations from './.vocab';
import { JobBadges, type JobBadgeProps } from './components/JobBadges';

import * as styles from './JobCard.css';

type Job = {
  solMetadata?: any;
  id: string;
  title: string;
  advertiserName?: string;
  salaryLabel?: string;
  salaryCurrencyLabel?: string;
  locationLabel?: string;
  listingDateLabel?: string;
  srcLogo?: string;
  isExpired?: boolean;
};

interface HomepageLayoutRefreshJobCardProps {
  'aria-label'?: string;
  jobInfo: Job;
  location?: {
    queryParams?: Record<string, string>;
    solHash?: string;
  };
  analytics?: {
    linkName: string;
    linkSection: string;
  };
  position?: number;
}

export const HOMEPAGE_JOB_CARD_LOGO_HEIGHT = 65;
const HOMEPAGE_JOB_CARD_LOGO_MIN_WIDTH = 65;
export const HOMEPAGE_JOB_CARD_LOGO_MAX_WIDTH = 100;

const constructPath = (
  path: string,
  location?: HomepageLayoutRefreshJobCardProps['location'],
) => {
  const searchParams = location?.queryParams
    ? `?${getSearchParams(location.queryParams).toString()}`
    : '';

  const solHash = location?.solHash ? `#sol=${location.solHash}` : '';

  return `${path}${searchParams}${solHash}`;
};

const HomepageLayoutRefreshJobCard = ({
  'aria-label': ariaLabel,
  jobInfo,
  location,
  analytics,
  position,
}: HomepageLayoutRefreshJobCardProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();

  const {
    id,
    title,
    advertiserName,
    salaryLabel,
    salaryCurrencyLabel,
    locationLabel,
    listingDateLabel,
    srcLogo,
    isExpired,
  } = jobInfo;

  const salaryDisplayLabel = useSalaryDisplayLabel({
    currencyLabel: salaryCurrencyLabel,
    salary: salaryLabel,
  });

  const badges: JobBadgeProps[] = [];

  if (isExpired) {
    badges.push({
      label: t('Expired'),
      tone: 'neutral',
    });
  }

  const jobUrl = useLocalisedLinks({
    path: constructPath(
      isExpired ? `/expiredjob/${id}` : `/job/${id}`,
      location,
    ),
  });

  const onJobCardClick = useCallback(() => {
    analyticsFacade.linkClicked({
      href: jobUrl,
      linkName: analytics?.linkName ?? '',
      linkContext: {
        jobId: id,
        linkPosition: String(position),
        linkSection: analytics?.linkSection,
      },
    });
  }, [analyticsFacade, id, position, jobUrl, analytics]);

  return (
    <Link
      href={jobUrl}
      aria-label={ariaLabel}
      className={[
        styles.jobCardBase,
        styles.hoveredJobCard,
        styles.focusedJobCard,
      ]}
      onClick={onJobCardClick}
    >
      <Stack space="medium">
        <Columns space="small" collapseBelow="tablet" reverse>
          {srcLogo ? (
            <Column width="content">
              <Box
                style={{
                  height: HOMEPAGE_JOB_CARD_LOGO_HEIGHT,
                  maxWidth: HOMEPAGE_JOB_CARD_LOGO_MAX_WIDTH,
                  minWidth: HOMEPAGE_JOB_CARD_LOGO_MIN_WIDTH,
                }}
              >
                <Box display="flex" height="full">
                  <LazyLoadedImg src={srcLogo} />
                </Box>
              </Box>
            </Column>
          ) : null}
          <Column>
            <Stack space="small">
              <Stack space="xsmall">
                <Text size="large" weight="medium">
                  {title}
                </Text>
                <Text tone="secondary">{advertiserName}</Text>
              </Stack>
              <Stack space="small">
                {isExpired && <JobBadges badges={badges} />}
                <Stack space="xsmall">
                  {locationLabel ? (
                    <Text size="small" tone="secondary">
                      {locationLabel}
                    </Text>
                  ) : null}
                  {salaryDisplayLabel ? (
                    <Text size="small" tone="secondary">
                      {salaryDisplayLabel}
                    </Text>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Column>
        </Columns>
        {listingDateLabel ? (
          <Text size="xsmall" tone="secondary">
            {listingDateLabel}
          </Text>
        ) : null}
      </Stack>
    </Link>
  );
};

export default HomepageLayoutRefreshJobCard;
