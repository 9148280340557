import { GoogleOneTap } from '@seek/online-identity-ui-packages';
import { TextLinkButton } from 'braid-design-system';
import { useCallback } from 'react';

import { isGoogleOneTapEnabled } from 'src/config';
import { useAppConfig } from 'src/config/appConfig';
import {
  useAnalyticsFacade,
  type GoogleOneTapActionOrigin,
} from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import { selectAuthenticated, selectLocation } from 'src/store/selectors';
import { getSearchParams } from 'src/utils/urlParams';

const useReturnPath = () => {
  const currentLocation = useSelector(selectLocation);
  let returnPath = currentLocation.pathname;

  if (Object.keys(currentLocation.query ?? {}).length) {
    returnPath += `?${getSearchParams(currentLocation.query).toString()}`;
  }

  if (currentLocation.hash) {
    returnPath += currentLocation.hash;
  }

  return returnPath;
};

interface GoogleOneTapWrapperProps {
  page: GoogleOneTapActionOrigin;
}

export const GoogleOneTapWrapper = ({ page }: GoogleOneTapWrapperProps) => {
  const { locale } = useAppConfig();
  const isAuthenticated = useSelector(selectAuthenticated);
  const returnPath = useReturnPath();

  const analyticsFacade = useAnalyticsFacade();

  const onSuccess = useCallback(() => {
    analyticsFacade.googleOneTapPressed({
      actionOrigin: page,
    });
  }, [analyticsFacade, page]);

  return isGoogleOneTapEnabled ? (
    <GoogleOneTap
      isAuthenticated={isAuthenticated}
      language={locale}
      returnPath={returnPath}
      onSuccess={onSuccess}
    />
  ) : (
    // This allows us to test the Google One Tap tracking in environments where it is not enabled
    !isAuthenticated && (
      <TextLinkButton data={{ automation: 'g-1-t-btn' }} onClick={onSuccess}>
        <></>
      </TextLinkButton>
    )
  );
};
